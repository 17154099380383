.search-ui {
    position: fixed;
    background: #f9f9f9;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    padding: .75rem 1.5rem 1.5rem 4.5rem;
    z-index: 9999;
    .search-header {
        .logo {
            height: 64px;
            width: auto;
        }
    }
    input.search-input {
        font-size: 4rem;
        font-weight: 600;
        border: 0;
        margin-bottom: 1.5rem;
        padding-left: 2rem;
        background: #f9f9f9;
        &:focus {
            outline: 0;
        }
        &::placeholder {
            color: $gray-400;
        }
    }
    .search-title {
        margin-bottom: 1.25rem;
        span {
            font-weight: 600;
        }
    }

    .card {
        transition: .1s ease-in-out;
    }

    .card:hover {
        background: #fdfdfd;
        box-shadow: 0 4px 20px 2px rgba(0, 0, 0, 0.1), 0 1px 6px rgba(0, 0, 0, 0.1);
    }
}

@media (max-width: 767px) {
    .search-ui {
        padding: 1rem;
    }
}