@import "variables";

.ng5-slider .ng5-slider-pointer {
    width: 24px !important;
    height: 24px !important;
    border-radius: 50% !important;
    top: -10px !important;
    background-color: $primary-base !important;
}

.ng5-slider .ng5-slider-selection {
    z-index: 2;
    background-color: $primary-base !important;
}

.ng5-slider .ng5-slider-pointer:after {
    top: 8px !important;
    left: 8px !important;
    width: 8px !important;
    height: 8px !important;
    border-radius: 4px !important;
    background: #fff;
}

.ng5-slider .ng5-slider-pointer.ng5-slider-active:after {
    background: #fff !important;
}

.ng5-slider-span:focus {
    outline: none;
}

.cursor-pointer {
    cursor: pointer;
}

.grid-selection {
    font-size: 20px;
    color: black;
}

.establishment-creation {
    margin-top: 50px;
}

.creation-form {
    label,
    p {
        font-size: 1rem;
    }

    .form-control {
        font-size: 1rem;
    }

    input.form-control {
        height: 42px;
    }

    select.form-control {
        height: 42px;
    }

    textarea.form-control {
        height: 42px;
        height: 8rem;
    }

    .subtitle {
        font-size: 1.05em;
        padding-bottom: 0.25em;
    }
}

@media (min-width: 768px) {
    .form-section-title {
        text-align: right;
    }
}

.creation-legend,
.icon-legend {
    text-align: center;
    font-size: 10em;
    margin-top: 50px;
    opacity: 0.2;
}

.btn-no-background {
    background: none;
    color: rgb(83, 83, 83);
}

.link-back .btn-link {
    margin-bottom: 1em;
    font-size: 1.2em;
}

.ng2-dropdown-menu {
    z-index: 9999999 !important;
}

.daterangepicker .calendar td,
.daterangepicker .calendar th {
    white-space: nowrap;
    text-align: center;
    min-width: 32px;
    padding: 8px;
}

.btn-group-xs > .btn,
.btn-xs {
    padding: 0.25rem 0.4rem;
    font-size: 0.875rem;
    line-height: 0.5;
    border-radius: 0.2rem;
}

.btn-link:hover i {
    text-decoration: none;
}

.btn-file {
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.btn-file input[type="file"] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 0;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    display: block;
    cursor: pointer;
}

.ng-select.ng-select-single .ng-select-container {
    height: 42px;
}

.ng-select .ng-select-container {
    background: #f8f9fa;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container,
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
    font-size: 1rem;
}

.ng-select .ng-select-container .ng-value-container .ng-input > input {
    font-size: 1rem;
    margin-top: 2px;
}

.resa-popover-content {
    font-size: 0.9rem;

    div {
        margin: 5px 0;
    }
}

.textarea-language {
    position: relative;

    .language-selector {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background-color: #f5f5f5;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
        padding: 5px 15px;
        border: 1px solid #ced4da;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

    textarea:focus + .language-selector {
        border-color: #8d9be3;
        border-bottom-color: #ced4da;
    }

    textarea {
        padding-top: 50px;
    }
}

.ngx-datatable.material
    .datatable-body
    .datatable-body-row
    .datatable-body-cell {
    padding: 0.5rem;
}

.ng-dirty.ng-invalid {
    @extend .is-invalid;
}

.form-group .error {
    display: none;
}

.ng-dirty.ng-invalid + .error {
    display: block;
}

.btn-airbnb {
    color: #ffffff;
    background-color: #ff5a5f;
    border-color: #f55156;
}

.btn-airbnb:hover,
.btn-airbnb:focus,
.btn-airbnb:active,
.btn-airbnb.active,
.open .dropdown-toggle.btn-airbnb {
    color: #ffffff;
    background-color: #eb4d53;
    border-color: #f55156;
}

.btn-airbnb:active,
.btn-airbnb.active,
.open .dropdown-toggle.btn-airbnb {
    background-image: none;
}

.btn-airbnb.disabled,
.btn-airbnb[disabled],
fieldset[disabled] .btn-airbnb,
.btn-airbnb.disabled:hover,
.btn-airbnb[disabled]:hover,
fieldset[disabled] .btn-airbnb:hover,
.btn-airbnb.disabled:focus,
.btn-airbnb[disabled]:focus,
fieldset[disabled] .btn-airbnb:focus,
.btn-airbnb.disabled:active,
.btn-airbnb[disabled]:active,
fieldset[disabled] .btn-airbnb:active,
.btn-airbnb.disabled.active,
.btn-airbnb[disabled].active,
fieldset[disabled] .btn-airbnb.active {
    background-color: #ff5a5f;
    border-color: #f55156;
}

.btn-airbnb .badge {
    color: #ff5a5f;
    background-color: #ffffff;
}

.btn-xs {
    font-size: 1em;
}

.ck-editor__main {
    h2,
    h3,
    h4 {
        color: #a7a7a7;
    }
    h2 {
        font-size: 1.3em;
    }
    h3 {
        font-size: 1.2em;
    }
    h4 {
        font-size: 1.1em;
    }

    h1,
    h2 {
        margin-bottom: 20px;
    }

    h3 {
        margin-bottom: 10px;
    }

    .image {
        margin: auto;
    }

    .image img {
        width: 100%;
    }

    .image > figcaption {
        caption-side: bottom;
        word-break: break-word;
        color: #333;
        background-color: #f7f7f7;
        padding: 0.6em;
        text-align: center;
        outline-offset: -1px;
    }

    .image-style-side,
    .image-style-align-left,
    .image-style-align-center,
    .image-style-align-right {
        max-width: 50%;
    }

    .image-style-side {
        float: right;
        margin-left: var(--ck-image-style-spacing);
    }

    .image-style-align-left {
        float: left;
        margin-right: var(--ck-image-style-spacing);
    }

    .image-style-align-center {
        margin-left: auto;
        margin-right: auto;
    }

    .image-style-align-right {
        float: right;
        margin-left: var(--ck-image-style-spacing);
    }
    .text-tiny {
        font-size: 0.7em;
    }
    .text-small {
        font-size: 0.85em;
    }

    .text-big {
        font-size: 1.4em;
    }

    .text-huge {
        font-size: 1.8em;
    }

    table {
        width: 100%;
        td {
            border-top: none;
        }
    }
}

.ck-editor__main table {
    display: block;
    tr {
        display: flex;
        td {
            text-align: left;
            display: block;
            flex-grow: 1;
            flex-basis: 0;
        }
    }
}

@media (max-width: 767px) {
    .ck-editor__main table {
        display: block;
        tr {
            display: block;
            td {
                text-align: left;
            }
        }
    }
}
