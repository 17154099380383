.inbox-main-sidebar-container {
    padding: 15px;
    .inbox-intro {
        
    }
    .inbox-main-sidebar {
        width: 180px;
        height: 100%;
        overflow: hidden;
        .inbox-main-nav {
            list-style: none;
            padding: 0;
            margin: 0;
            li {
                a {
                    padding: .66rem 0;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    color: $foreground;
                    &.active {
                        color: $primary;
                    }
                    &:hover {
                        color: $primary;
                    }
                    i {
                        margin-right: 8px;
                    }
                }
            }
        }
    }
    
    .inbox-secondary-sidebar-container {
        border-radius: 10px;
        min-height: calc(100vh - 150px);
        .inbox-secondary-sidebar-content {
            .inbox-topbar {
                height: 52px;
                display: flex;
                flex-direction: row;
            }
            .inbox-details {
                padding: 1.5rem 2rem
            }
        }
        .inbox-secondary-sidebar {
            overflow: hidden;
            width: 360px;
            height: 100%;
            border-right: 1px solid $gray-200;
            .mail-item {
                display: flex;
                border-bottom: 1px solid $gray-200;
                padding: 1.25rem 1rem;
                cursor: pointer;
                &:hover {
                    background: $gray-100;
                }
                .avatar {
                    width: 15%;
                }
                .details {
                    width: 60%;
                }
                .date {
                    width: 25%;
                    font-size: 10px;
                    span {
                        float: right;
                    }
                }
            }
            .avatar {
                img {
                    margin: 4px;
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                }
            }
            .name {
                font-size: 12px;
                display: block;
            }
        }
    }
}

@media (max-width: 767px) {
    .mail-item {
        padding: 1rem .5rem !important;
    }
    .inbox-secondary-sidebar {
        width: 280px !important;
    }
}