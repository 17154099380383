@font-face {
  font-family: 'iconsmind';
  src:  url('iconsmind.eot?ioqkm7');
  src:  url('iconsmind.eot?ioqkm7#iefix') format('embedded-opentype'),
    url('iconsmind.ttf?ioqkm7') format('truetype'),
    url('iconsmind.woff?ioqkm7') format('woff'),
    url('iconsmind.svg?ioqkm7#iconsmind') format('svg');
  font-weight: normal;
  font-style: normal;
}

i.im {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'iconsmind';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.i-Add-Window:before {
  content: "\e918";
}
.i-Approved-Window:before {
  content: "\e919";
}
.i-Block-Window:before {
  content: "\e91a";
}
.i-Close-Window:before {
  content: "\e91b";
}
.i-Code-Window:before {
  content: "\e91c";
}
.i-Delete-Window:before {
  content: "\e91d";
}
.i-Download-Window:before {
  content: "\e91e";
}
.i-Duplicate-Window:before {
  content: "\e91f";
}
.i-Error-404-Window:before {
  content: "\e920";
}
.i-Favorite-Window:before {
  content: "\e921";
}
.i-Font-Window:before {
  content: "\e922";
}
.i-Full-View-Window:before {
  content: "\e923";
}
.i-Height-Window:before {
  content: "\e924";
}
.i-Home-Window:before {
  content: "\e925";
}
.i-Info-Window:before {
  content: "\e926";
}
.i-Loading-Window:before {
  content: "\e927";
}
.i-Lock-Window:before {
  content: "\e928";
}
.i-Love-Window:before {
  content: "\e929";
}
.i-Maximize-Window:before {
  content: "\e92a";
}
.i-Minimize-Maximize-Close-Window:before {
  content: "\e92b";
}
.i-Minimize-Window:before {
  content: "\e92c";
}
.i-Navigation-Left-Window:before {
  content: "\e92d";
}
.i-Navigation-Right-Window:before {
  content: "\e92e";
}
.i-Network-Window:before {
  content: "\e92f";
}
.i-New-Tab:before {
  content: "\e930";
}
.i-One-Window:before {
  content: "\e931";
}
.i-Refresh-Window:before {
  content: "\e932";
}
.i-Remove-Window:before {
  content: "\e933";
}
.i-Restore-Window:before {
  content: "\e934";
}
.i-Save-Window:before {
  content: "\e935";
}
.i-Settings-Window:before {
  content: "\e936";
}
.i-Share-Window:before {
  content: "\e937";
}
.i-Sidebar-Window:before {
  content: "\e938";
}
.i-Split-Four-Square-Window:before {
  content: "\e939";
}
.i-Split-Horizontal-2-Window:before {
  content: "\e93a";
}
.i-Split-Horizontal:before {
  content: "\e93b";
}
.i-Split-Vertical-2:before {
  content: "\e93c";
}
.i-Split-Vertical:before {
  content: "\e93d";
}
.i-Split-Window:before {
  content: "\e93e";
}
.i-Time-Window:before {
  content: "\e93f";
}
.i-Touch-Window:before {
  content: "\e940";
}
.i-Two-Windows:before {
  content: "\e941";
}
.i-Upload-Window:before {
  content: "\e942";
}
.i-URL-Window:before {
  content: "\e943";
}
.i-Warning-Window:before {
  content: "\e944";
}
.i-Width-Window:before {
  content: "\e945";
}
.i-Window-2:before {
  content: "\e946";
}
.i-Windows-2:before {
  content: "\e947";
}
.i-Cloud-Sun:before {
  content: "\e94e";
}
.i-Cloud-Weather:before {
  content: "\e94f";
}
.i-Clouds-Weather:before {
  content: "\e950";
}
.i-Drop:before {
  content: "\e951";
}
.i-Dry:before {
  content: "\e952";
}
.i-Fahrenheit:before {
  content: "\e953";
}
.i-Windsock:before {
  content: "\e973";
}
.i-Film-Video:before {
  content: "\e97e";
}
.i-Film:before {
  content: "\e97f";
}
.i-Flash-Video:before {
  content: "\e980";
}
.i-HD-Video:before {
  content: "\e981";
}
.i-Movie:before {
  content: "\e982";
}
.i-Old-TV:before {
  content: "\e983";
}
.i-Reel:before {
  content: "\e984";
}
.i-Tripod-and-Video:before {
  content: "\e985";
}
.i-TV:before {
  content: "\e986";
}
.i-Video-2:before {
  content: "\e987";
}
.i-Video-3:before {
  content: "\e988";
}
.i-Video-4:before {
  content: "\e989";
}
.i-Video-5:before {
  content: "\e98a";
}
.i-Video-6:before {
  content: "\e98b";
}
.i-Video-Photographer:before {
  content: "\e98e";
}
.i-Video-Tripod:before {
  content: "\e98f";
}
.i-Video:before {
  content: "\e990";
}
.i-Affiliate:before {
  content: "\e991";
}
.i-Background:before {
  content: "\e992";
}
.i-Billing:before {
  content: "\e993";
}
.i-Control-2:before {
  content: "\e994";
}
.i-Control:before {
  content: "\e995";
}
.i-Crop-2:before {
  content: "\e996";
}
.i-Dashboard:before {
  content: "\e997";
}
.i-Duplicate-Layer:before {
  content: "\e998";
}
.i-Filter-2:before {
  content: "\e999";
}
.i-Gear-2:before {
  content: "\e99a";
}
.i-Gear:before {
  content: "\e99b";
}
.i-Gears-2:before {
  content: "\e99c";
}
.i-Gears:before {
  content: "\e99d";
}
.i-Information:before {
  content: "\e99e";
}
.i-Layer-Backward:before {
  content: "\e99f";
}
.i-Layer-Forward:before {
  content: "\e9a0";
}
.i-Library:before {
  content: "\e9a1";
}
.i-Loading-2:before {
  content: "\e9a2";
}
.i-Loading-3:before {
  content: "\e9a3";
}
.i-Loading:before {
  content: "\e9a4";
}
.i-Magnifi-Glass:before {
  content: "\e9a5";
}
.i-Magnifi-Glass-:before {
  content: "\e9a6";
}
.i-Magnifi-Glass1:before {
  content: "\e9a7";
}
.i-Share:before {
  content: "\e9b5";
}
.i-Statistic:before {
  content: "\e9b6";
}
.i-Support:before {
  content: "\e9b7";
}
.i-Bicycle-2:before {
  content: "\e9be";
}
.i-Bicycle:before {
  content: "\e9bf";
}
.i-Car-2:before {
  content: "\e9c4";
}
.i-Car-3:before {
  content: "\e9c5";
}
.i-Car-Wheel:before {
  content: "\e9c6";
}
.i-Car:before {
  content: "\e9c7";
}
.i-Gaugage-2:before {
  content: "\e9c8";
}
.i-Gaugage:before {
  content: "\e9c9";
}
.i-Helicopter-2:before {
  content: "\e9ca";
}
.i-Helicopter:before {
  content: "\e9cb";
}
.i-Helmet:before {
  content: "\e9cc";
}
.i-Jeep-2:before {
  content: "\e9cd";
}
.i-Jeep:before {
  content: "\e9ce";
}
.i-Jet:before {
  content: "\e9cf";
}
.i-Motorcycle:before {
  content: "\e9d0";
}
.i-Plane-2:before {
  content: "\e9d1";
}
.i-Plane:before {
  content: "\e9d2";
}
.i-Road-2:before {
  content: "\e9d3";
}
.i-Road:before {
  content: "\e9d4";
}
.i-Rocket:before {
  content: "\e9d5";
}
.i-Sailing-Ship:before {
  content: "\e9d6";
}
.i-Scooter-Front:before {
  content: "\e9d7";
}
.i-Scooter:before {
  content: "\e9d8";
}
.i-Ship-2:before {
  content: "\e9d9";
}
.i-Ship:before {
  content: "\e9da";
}
.i-Skateboard-2:before {
  content: "\e9db";
}
.i-Skateboard:before {
  content: "\e9dc";
}
.i-Taxi-2:before {
  content: "\e9dd";
}
.i-Taxi-Sign:before {
  content: "\e9de";
}
.i-Taxi:before {
  content: "\e9df";
}
.i-Tractor:before {
  content: "\e9e0";
}
.i-Traffic-Light-2:before {
  content: "\e9e1";
}
.i-traffic-Light:before {
  content: "\e9e2";
}
.i-Train-2:before {
  content: "\e9e3";
}
.i-Train:before {
  content: "\e9e4";
}
.i-Tram:before {
  content: "\e9e5";
}
.i-Truck:before {
  content: "\e9e6";
}
.i-Yacht:before {
  content: "\e9e7";
}
.i-Double-Tap:before {
  content: "\e9e8";
}
.i-Drag-Down:before {
  content: "\e9e9";
}
.i-Drag-Left:before {
  content: "\e9ea";
}
.i-Drag-Right:before {
  content: "\e9eb";
}
.i-Drag-Up:before {
  content: "\e9ec";
}
.i-Drag:before {
  content: "\e9ed";
}
.i-Clock-3:before {
  content: "\ea18";
}
.i-Clock-4:before {
  content: "\ea19";
}
.i-Clock-Back:before {
  content: "\ea1a";
}
.i-Clock-Forward:before {
  content: "\ea1b";
}
.i-Clock:before {
  content: "\ea1c";
}
.i-Old-Clock:before {
  content: "\ea1d";
}
.i-Over-Time-2:before {
  content: "\ea1e";
}
.i-Over-Time:before {
  content: "\ea1f";
}
.i-Sand-watch-2:before {
  content: "\ea20";
}
.i-Sand-watch:before {
  content: "\ea21";
}
.i-Stopwatch-2:before {
  content: "\ea22";
}
.i-Stopwatch:before {
  content: "\ea23";
}
.i-Time-Backup:before {
  content: "\ea24";
}
.i-Medal-2:before {
  content: "\ea5e";
}
.i-Medal-3:before {
  content: "\ea5f";
}
.i-Medal:before {
  content: "\ea60";
}
.i-Speach-Bubble-2:before {
  content: "\ea77";
}
.i-Speach-Bubble-3:before {
  content: "\ea78";
}
.i-Speach-Bubble-4:before {
  content: "\ea79";
}
.i-Speach-Bubble-5:before {
  content: "\ea7a";
}
.i-Speach-Bubble-6:before {
  content: "\ea7b";
}
.i-Speach-Bubble-7:before {
  content: "\ea7c";
}
.i-Speach-Bubble-8:before {
  content: "\ea7d";
}
.i-Speach-Bubble-9:before {
  content: "\ea7e";
}
.i-Speach-Bubble-10:before {
  content: "\ea7f";
}
.i-Speach-Bubble-11:before {
  content: "\ea80";
}
.i-Speach-Bubble-12:before {
  content: "\ea81";
}
.i-Speach-Bubble-13:before {
  content: "\ea82";
}
.i-Speach-Bubble-Asking:before {
  content: "\ea83";
}
.i-Speach-Bubble-Dialog:before {
  content: "\ea88";
}
.i-Speach-Bubble:before {
  content: "\ea89";
}
.i-Speach-Bubbles:before {
  content: "\ea8a";
}
.i-Aim:before {
  content: "\ea8b";
}
.i-Ask:before {
  content: "\ea8c";
}
.i-Bebo:before {
  content: "\ea8d";
}
.i-Behance:before {
  content: "\ea8e";
}
.i-Betvibes:before {
  content: "\ea8f";
}
.i-Bing:before {
  content: "\ea90";
}
.i-Blinklist:before {
  content: "\ea91";
}
.i-Blogger:before {
  content: "\ea92";
}
.i-Brightkite:before {
  content: "\ea93";
}
.i-Delicious:before {
  content: "\ea94";
}
.i-Deviantart:before {
  content: "\ea95";
}
.i-Digg:before {
  content: "\ea96";
}
.i-Diigo:before {
  content: "\ea97";
}
.i-Doplr:before {
  content: "\ea98";
}
.i-Dribble:before {
  content: "\ea99";
}
.i-Email:before {
  content: "\ea9a";
}
.i-Evernote:before {
  content: "\ea9b";
}
.i-Facebook-2:before {
  content: "\ea9c";
}
.i-Facebook:before {
  content: "\ea9d";
}
.i-Feedburner:before {
  content: "\ea9e";
}
.i-Flickr:before {
  content: "\ea9f";
}
.i-Formspring:before {
  content: "\eaa0";
}
.i-Forsquare:before {
  content: "\eaa1";
}
.i-Friendfeed:before {
  content: "\eaa2";
}
.i-Friendster:before {
  content: "\eaa3";
}
.i-Furl:before {
  content: "\eaa4";
}
.i-Google-Buzz:before {
  content: "\eaa5";
}
.i-Google-Plus:before {
  content: "\eaa6";
}
.i-Google:before {
  content: "\eaa7";
}
.i-Gowalla:before {
  content: "\eaa8";
}
.i-ICQ:before {
  content: "\eaa9";
}
.i-ImDB:before {
  content: "\eaaa";
}
.i-Instagram:before {
  content: "\eaab";
}
.i-Last-FM:before {
  content: "\eaac";
}
.i-Like-2:before {
  content: "\eaad";
}
.i-Like:before {
  content: "\eaae";
}
.i-Linkedin-2:before {
  content: "\eaaf";
}
.i-Linkedin:before {
  content: "\eab0";
}
.i-Livejournal:before {
  content: "\eab1";
}
.i-Metacafe:before {
  content: "\eab2";
}
.i-Mixx:before {
  content: "\eab3";
}
.i-Myspace:before {
  content: "\eab4";
}
.i-Newsvine:before {
  content: "\eab5";
}
.i-Orkut:before {
  content: "\eab6";
}
.i-Picasa:before {
  content: "\eab7";
}
.i-Pinterest:before {
  content: "\eab8";
}
.i-Plaxo:before {
  content: "\eab9";
}
.i-Plurk:before {
  content: "\eaba";
}
.i-Posterous:before {
  content: "\eabb";
}
.i-QIK:before {
  content: "\eabc";
}
.i-Reddit:before {
  content: "\eabd";
}
.i-Reverbnation:before {
  content: "\eabe";
}
.i-RSS:before {
  content: "\eabf";
}
.i-Sharethis:before {
  content: "\eac0";
}
.i-Shoutwire:before {
  content: "\eac1";
}
.i-Skype:before {
  content: "\eac2";
}
.i-Soundcloud:before {
  content: "\eac3";
}
.i-Spurl:before {
  content: "\eac4";
}
.i-Stumbleupon:before {
  content: "\eac5";
}
.i-Technorati:before {
  content: "\eac6";
}
.i-Tumblr:before {
  content: "\eac7";
}
.i-Twitter-2:before {
  content: "\eac8";
}
.i-Twitter:before {
  content: "\eac9";
}
.i-Unlike-2:before {
  content: "\eaca";
}
.i-Unlike:before {
  content: "\eacb";
}
.i-Ustream:before {
  content: "\eacc";
}
.i-Viddler:before {
  content: "\eacd";
}
.i-Vimeo:before {
  content: "\eace";
}
.i-Wordpress:before {
  content: "\eacf";
}
.i-Xanga:before {
  content: "\ead0";
}
.i-Xing:before {
  content: "\ead1";
}
.i-Yahoo-Buzz:before {
  content: "\ead2";
}
.i-Yahoo:before {
  content: "\ead3";
}
.i-Yelp:before {
  content: "\ead4";
}
.i-Youtube:before {
  content: "\ead5";
}
.i-Zootool:before {
  content: "\ead6";
}
.i-Bisexual:before {
  content: "\ead7";
}
.i-Cancer1:before {
  content: "\ead8";
}
.i-Couple-Sign:before {
  content: "\ead9";
}
.i-David-Star:before {
  content: "\eada";
}
.i-Family-Sign:before {
  content: "\eadb";
}
.i-Female-2:before {
  content: "\eadc";
}
.i-Gey:before {
  content: "\eadd";
}
.i-Heart:before {
  content: "\eade";
}
.i-Homosexual:before {
  content: "\eadf";
}
.i-Inifity:before {
  content: "\eae0";
}
.i-Lesbian:before {
  content: "\eae1";
}
.i-Lesbians:before {
  content: "\eae2";
}
.i-Love:before {
  content: "\eae3";
}
.i-Male-2:before {
  content: "\eae4";
}
.i-Men:before {
  content: "\eae5";
}
.i-No-Smoking:before {
  content: "\eae6";
}
.i-Paw:before {
  content: "\eae7";
}
.i-Quotes-2:before {
  content: "\eae8";
}
.i-Quotes:before {
  content: "\eae9";
}
.i-Add-Cart:before {
  content: "\eaf8";
}
.i-Bag-Coins:before {
  content: "\eaf9";
}
.i-Bag-Items:before {
  content: "\eafa";
}
.i-Bar-Code:before {
  content: "\eafc";
}
.i-Bitcoin:before {
  content: "\eb00";
}
.i-Car-Coins:before {
  content: "\eb01";
}
.i-Car-Items:before {
  content: "\eb02";
}
.i-Cart-Quantity:before {
  content: "\eb03";
}
.i-Cash-register-2:before {
  content: "\eb04";
}
.i-Cash-Register:before {
  content: "\eb05";
}
.i-Checkout-Bag:before {
  content: "\eb06";
}
.i-Checkout-Basket:before {
  content: "\eb07";
}
.i-Checkout:before {
  content: "\eb08";
}
.i-Full-Basket:before {
  content: "\eb09";
}
.i-Full-Cart:before {
  content: "\eb0a";
}
.i-Password-shopping:before {
  content: "\eb0e";
}
.i-Receipt-3:before {
  content: "\eb11";
}
.i-Receipt-4:before {
  content: "\eb12";
}
.i-Receipt:before {
  content: "\eb13";
}
.i-Remove-Bag:before {
  content: "\eb14";
}
.i-Remove-Basket:before {
  content: "\eb15";
}
.i-Remove-Cart:before {
  content: "\eb16";
}
.i-Shop-2:before {
  content: "\eb17";
}
.i-Shop-3:before {
  content: "\eb18";
}
.i-Shop-4:before {
  content: "\eb19";
}
.i-Shop:before {
  content: "\eb1a";
}
.i-Shopping-Bag:before {
  content: "\eb1b";
}
.i-Shopping-Basket:before {
  content: "\eb1c";
}
.i-Shopping-Cart:before {
  content: "\eb1d";
}
.i-Tag-2:before {
  content: "\eb1e";
}
.i-Tag-3:before {
  content: "\eb1f";
}
.i-Tag-4:before {
  content: "\eb20";
}
.i-Tag-5:before {
  content: "\eb21";
}
.i-This-Side-Up:before {
  content: "\eb22";
}
.i-Broke-Link-2:before {
  content: "\eb23";
}
.i-Coding:before {
  content: "\eb24";
}
.i-Consulting:before {
  content: "\eb25";
}
.i-Copyright:before {
  content: "\eb26";
}
.i-Idea-2:before {
  content: "\eb27";
}
.i-Idea-3:before {
  content: "\eb28";
}
.i-Internet-2:before {
  content: "\eb2b";
}
.i-Internet:before {
  content: "\eb2c";
}
.i-Link-2:before {
  content: "\eb2d";
}
.i-Management:before {
  content: "\eb2e";
}
.i-Monitor-Analytics:before {
  content: "\eb2f";
}
.i-Monitoring:before {
  content: "\eb30";
}
.i-Optimization:before {
  content: "\eb31";
}
.i-Search-People:before {
  content: "\eb32";
}
.i-Tag:before {
  content: "\eb33";
}
.i-Target-Market:before {
  content: "\eb34";
}
.i-Target:before {
  content: "\eb35";
}
.i-Testimonal:before {
  content: "\eb36";
}
.i-Computer-Secure:before {
  content: "\eb37";
}
.i-Eye-Scan:before {
  content: "\eb38";
}
.i-Finger-Print:before {
  content: "\eb39";
}
.i-Firewall:before {
  content: "\eb3a";
}
.i-Key-Lock:before {
  content: "\eb3b";
}
.i-Laptop-Secure:before {
  content: "\eb3c";
}
.i-Layer-1:before {
  content: "\eb3d";
}
.i-532:before {
  content: "\eb3d";
}
.i-Lock-2:before {
  content: "\eb3e";
}
.i-Lock-3:before {
  content: "\eb3f";
}
.i-Lock:before {
  content: "\eb40";
}
.i-Password-Field:before {
  content: "\eb41";
}
.i-Password:before {
  content: "\eb42";
}
.i-Police:before {
  content: "\eb43";
}
.i-Safe-Box:before {
  content: "\eb44";
}
.i-Security-Block:before {
  content: "\eb45";
}
.i-Security-Bug:before {
  content: "\eb46";
}
.i-Security-Camera:before {
  content: "\eb47";
}
.i-Security-Check:before {
  content: "\eb48";
}
.i-Security-Settings:before {
  content: "\eb49";
}
.i-Securiy-Remove:before {
  content: "\eb4a";
}
.i-Shield:before {
  content: "\eb4b";
}
.i-Smartphone--Secure:before {
  content: "\eb4c";
}
.i-SSL:before {
  content: "\eb4d";
}
.i-Tablet-Secure:before {
  content: "\eb4e";
}
.i-Type-Pass:before {
  content: "\eb4f";
}
.i-Unlock-2:before {
  content: "\eb50";
}
.i-Unlock-3:before {
  content: "\eb51";
}
.i-Unlock:before {
  content: "\eb52";
}
.i-Ambulance:before {
  content: "\eb53";
}
.i-Atom:before {
  content: "\eb55";
}
.i-Band-Aid:before {
  content: "\eb57";
}
.i-Bio-Hazard:before {
  content: "\eb58";
}
.i-Biotech:before {
  content: "\eb59";
}
.i-Brain:before {
  content: "\eb5a";
}
.i-Chemical-2:before {
  content: "\eb5b";
}
.i-Chemical-3:before {
  content: "\eb5c";
}
.i-Chemical-4:before {
  content: "\eb5d";
}
.i-Chemical-5:before {
  content: "\eb5e";
}
.i-Chemical:before {
  content: "\eb5f";
}
.i-Clinic:before {
  content: "\eb60";
}
.i-Cube-Molecule-2:before {
  content: "\eb61";
}
.i-Cube-Molecule:before {
  content: "\eb62";
}
.i-Danger-2:before {
  content: "\eb63";
}
.i-Danger:before {
  content: "\eb64";
}
.i-First-Aid:before {
  content: "\eb68";
}
.i-Flask-2:before {
  content: "\eb69";
}
.i-Flask:before {
  content: "\eb6a";
}
.i-Helix-2:before {
  content: "\eb6b";
}
.i-Hospital:before {
  content: "\eb6c";
}
.i-Medicine-2:before {
  content: "\eb6f";
}
.i-Medicine-3:before {
  content: "\eb70";
}
.i-Medicine:before {
  content: "\eb71";
}
.i-Microscope:before {
  content: "\eb72";
}
.i-Neutron:before {
  content: "\eb73";
}
.i-Nuclear:before {
  content: "\eb74";
}
.i-Physics:before {
  content: "\eb75";
}
.i-Plasmid:before {
  content: "\eb76";
}
.i-Pulse:before {
  content: "\eb78";
}
.i-Radioactive:before {
  content: "\eb79";
}
.i-Stethoscope:before {
  content: "\eb7d";
}
.i-Temperature1:before {
  content: "\eb7e";
}
.i-Test-Tube-2:before {
  content: "\eb7f";
}
.i-Test-Tube:before {
  content: "\eb80";
}
.i-Virus-2:before {
  content: "\eb81";
}
.i-Virus-3:before {
  content: "\eb82";
}
.i-Virus:before {
  content: "\eb83";
}
.i-Camera:before {
  content: "\eb8d";
}
.i-Edit:before {
  content: "\eb90";
}
.i-Eye:before {
  content: "\eb91";
}
.i-Film-Cartridge:before {
  content: "\eb92";
}
.i-Film1:before {
  content: "\eb93";
}
.i-Filter:before {
  content: "\eb94";
}
.i-Flash-2:before {
  content: "\eb95";
}
.i-Flash:before {
  content: "\eb96";
}
.i-Fluorescent:before {
  content: "\eb97";
}
.i-Gopro:before {
  content: "\eb98";
}
.i-Landscape:before {
  content: "\eb99";
}
.i-Len-2:before {
  content: "\eb9a";
}
.i-Len-3:before {
  content: "\eb9b";
}
.i-Len:before {
  content: "\eb9c";
}
.i-Macro:before {
  content: "\eb9d";
}
.i-Memory-Card-2:before {
  content: "\eb9e";
}
.i-Memory-Card-3:before {
  content: "\eb9f";
}
.i-Memory-Card:before {
  content: "\eba0";
}
.i-No-Flash:before {
  content: "\eba1";
}
.i-Shutter:before {
  content: "\ebae";
}
.i-Signal:before {
  content: "\ebaf";
}
.i-Snow1:before {
  content: "\ebb0";
}
.i-Sport-Mode:before {
  content: "\ebb1";
}
.i-Studio-Flash:before {
  content: "\ebb2";
}
.i-Studio-Lightbox:before {
  content: "\ebb3";
}
.i-Timer1:before {
  content: "\ebb4";
}
.i-Tripod-2:before {
  content: "\ebb5";
}
.i-Tripod-with-Camera:before {
  content: "\ebb6";
}
.i-Tripod-with-Gopro:before {
  content: "\ebb7";
}
.i-Add-User:before {
  content: "\ebb8";
}
.i-Add-UserStar:before {
  content: "\ebb9";
}
.i-Administrator:before {
  content: "\ebba";
}
.i-Alien-2:before {
  content: "\ebbb";
}
.i-Alien:before {
  content: "\ebbc";
}
.i-Assistant:before {
  content: "\ebbd";
}
.i-Baby-Cry:before {
  content: "\ebbe";
}
.i-Baby:before {
  content: "\ebbf";
}
.i-Boy:before {
  content: "\ebc0";
}
.i-Business-Man:before {
  content: "\ebc1";
}
.i-Business-ManWoman:before {
  content: "\ebc2";
}
.i-Business-Mens:before {
  content: "\ebc3";
}
.i-Business-Woman:before {
  content: "\ebc4";
}
.i-Checked-User:before {
  content: "\ebc5";
}
.i-Chef:before {
  content: "\ebc6";
}
.i-Conference:before {
  content: "\ebc7";
}
.i-Cool-Guy:before {
  content: "\ebc8";
}
.i-Criminal:before {
  content: "\ebc9";
}
.i-Dj:before {
  content: "\ebca";
}
.i-Doctor:before {
  content: "\ebcb";
}
.i-Engineering:before {
  content: "\ebcc";
}
.i-Farmer:before {
  content: "\ebcd";
}
.i-Female-21:before {
  content: "\ebce";
}
.i-Female:before {
  content: "\ebcf";
}
.i-Find-User:before {
  content: "\ebd0";
}
.i-Geek:before {
  content: "\ebd1";
}
.i-Genius:before {
  content: "\ebd2";
}
.i-Girl:before {
  content: "\ebd3";
}
.i-Headphone:before {
  content: "\ebd4";
}
.i-Headset:before {
  content: "\ebd5";
}
.i-ID-2:before {
  content: "\ebd6";
}
.i-ID-3:before {
  content: "\ebd7";
}
.i-ID-Card:before {
  content: "\ebd8";
}
.i-King-2:before {
  content: "\ebd9";
}
.i-Lock-User:before {
  content: "\ebda";
}
.i-Love-User:before {
  content: "\ebdb";
}
.i-Male-21:before {
  content: "\ebdc";
}
.i-Male:before {
  content: "\ebdd";
}
.i-MaleFemale:before {
  content: "\ebde";
}
.i-Man-Sign:before {
  content: "\ebdf";
}
.i-Mens:before {
  content: "\ebe0";
}
.i-Network:before {
  content: "\ebe1";
}
.i-Nurse:before {
  content: "\ebe2";
}
.i-Pilot:before {
  content: "\ebe4";
}
.i-Professor:before {
  content: "\ebe7";
}
.i-Punker:before {
  content: "\ebe8";
}
.i-Remove-User:before {
  content: "\ebea";
}
.i-Robot:before {
  content: "\ebeb";
}
.i-Speak-2:before {
  content: "\ebec";
}
.i-Speak:before {
  content: "\ebed";
}
.i-Spy:before {
  content: "\ebee";
}
.i-Student-Female:before {
  content: "\ebef";
}
.i-Student-Male:before {
  content: "\ebf0";
}
.i-Student-MaleFemale:before {
  content: "\ebf1";
}
.i-Students:before {
  content: "\ebf2";
}
.i-Superman:before {
  content: "\ebf3";
}
.i-Talk-Man:before {
  content: "\ebf4";
}
.i-Teacher:before {
  content: "\ebf5";
}
.i-Coffee-Machine:before {
  content: "\ec07";
}
.i-Flag-2:before {
  content: "\ec11";
}
.i-Flag:before {
  content: "\ec12";
}
.i-Flashlight:before {
  content: "\ec13";
}
.i-Gas-Pump:before {
  content: "\ec15";
}
.i-Gift-Box:before {
  content: "\ec16";
}
.i-Key-3:before {
  content: "\ec1d";
}
.i-Key:before {
  content: "\ec1e";
}
.i-Lamp:before {
  content: "\ec1f";
}
.i-Life-Safer:before {
  content: "\ec21";
}
.i-Light-Bulb:before {
  content: "\ec23";
}
.i-Luggage-2:before {
  content: "\ec25";
}
.i-Magnet:before {
  content: "\ec27";
}
.i-Mirror:before {
  content: "\ec2a";
}
.i-Movie-Ticket:before {
  content: "\ec2b";
}
.i-Office-Lamp:before {
  content: "\ec2c";
}
.i-Paint-Brush:before {
  content: "\ec2d";
}
.i-Paint-Bucket:before {
  content: "\ec2e";
}
.i-Paper-Plane:before {
  content: "\ec2f";
}
.i-Post-Sign-2-ways:before {
  content: "\ec30";
}
.i-Post-Sign:before {
  content: "\ec31";
}
.i-Scale:before {
  content: "\ec34";
}
.i-Stroller:before {
  content: "\ec38";
}
.i-Suitcase:before {
  content: "\ec39";
}
.i-Teddy-Bear:before {
  content: "\ec3a";
}
.i-Telescope:before {
  content: "\ec3b";
}
.i-Tent:before {
  content: "\ec3c";
}
.i-Thread:before {
  content: "\ec3d";
}
.i-Ticket:before {
  content: "\ec3e";
}
.i-Time-Bomb:before {
  content: "\ec3f";
}
.i-Tourch:before {
  content: "\ec40";
}
.i-Vase:before {
  content: "\ec41";
}
.i-Video-Game-Controller:before {
  content: "\ec42";
}
.i-Conservation:before {
  content: "\ec43";
}
.i-Eci-Icon:before {
  content: "\ec44";
}
.i-Environmental-2:before {
  content: "\ec45";
}
.i-Environmental-3:before {
  content: "\ec46";
}
.i-Green-Energy:before {
  content: "\ec4c";
}
.i-Green-House:before {
  content: "\ec4d";
}
.i-Landscape1:before {
  content: "\ec4e";
}
.i-Leafs-2:before {
  content: "\ec4f";
}
.i-Light-Bulb-Leaf:before {
  content: "\ec51";
}
.i-Palm-Tree:before {
  content: "\ec52";
}
.i-Plant:before {
  content: "\ec53";
}
.i-Recycling-2:before {
  content: "\ec54";
}
.i-Tree-3:before {
  content: "\ec59";
}
.i-Bell:before {
  content: "\ec5d";
}
.i-Casette-Tape:before {
  content: "\ec5e";
}
.i-Drum:before {
  content: "\ec63";
}
.i-Electric-Guitar:before {
  content: "\ec66";
}
.i-First:before {
  content: "\ec68";
}
.i-Keyboard3:before {
  content: "\ec6b";
}
.i-Last:before {
  content: "\ec6c";
}
.i-Microphone-3:before {
  content: "\ec71";
}
.i-Music-Note-2:before {
  content: "\ec79";
}
.i-Next-Music:before {
  content: "\ec7f";
}
.i-Old-Radio:before {
  content: "\ec80";
}
.i-On-Air:before {
  content: "\ec81";
}
.i-Play-Music:before {
  content: "\ec83";
}
.i-Stop-Music:before {
  content: "\ec8c";
}
.i-Trumpet:before {
  content: "\ec8d";
}
.i-Voice:before {
  content: "\ec8e";
}
.i-Pause-2:before {
  content: "\ec99";
}
.i-Pause:before {
  content: "\ec9a";
}
.i-Power-2:before {
  content: "\ec9b";
}
.i-Power-3:before {
  content: "\ec9c";
}
.i-Record-2:before {
  content: "\ec9d";
}
.i-Record1:before {
  content: "\ec9e";
}
.i-Repeat-2:before {
  content: "\ec9f";
}
.i-Repeat:before {
  content: "\eca0";
}
.i-Shuffle-2:before {
  content: "\eca1";
}
.i-Shuffle:before {
  content: "\eca2";
}
.i-Start-2:before {
  content: "\eca3";
}
.i-Start:before {
  content: "\eca4";
}
.i-Stop-2:before {
  content: "\eca5";
}
.i-Stop:before {
  content: "\eca6";
}
.i-Compass-2:before {
  content: "\eca7";
}
.i-Compass-Rose:before {
  content: "\eca8";
}
.i-Edit-Map:before {
  content: "\ecae";
}
.i-Geo2-:before {
  content: "\ecb7";
}
.i-Geo21:before {
  content: "\ecbc";
}
.i-Globe-2:before {
  content: "\ecc5";
}
.i-Globe:before {
  content: "\ecc6";
}
.i-Map-Marker:before {
  content: "\eccb";
}
.i-Map:before {
  content: "\eccc";
}
.i-Map2:before {
  content: "\eccd";
}
.i-Android-Store:before {
  content: "\ecd3";
}
.i-Apple-Store:before {
  content: "\ecd4";
}
.i-Box1:before {
  content: "\ecd5";
}
.i-Dropbox:before {
  content: "\ecd6";
}
.i-Google-Drive:before {
  content: "\ecd7";
}
.i-Google-Play:before {
  content: "\ecd8";
}
.i-Paypal:before {
  content: "\ecd9";
}
.i-Skrill:before {
  content: "\ecda";
}
.i-X-Box:before {
  content: "\ecdb";
}
.i-Add:before {
  content: "\ecdc";
}
.i-Back1:before {
  content: "\ecdd";
}
.i-Broken-Link:before {
  content: "\ecde";
}
.i-Check:before {
  content: "\ece0";
}
.i-Circular-Point:before {
  content: "\ece1";
}
.i-Close:before {
  content: "\ece2";
}
.i-Cursor-Click-2:before {
  content: "\ece3";
}
.i-Cursor-Click:before {
  content: "\ece4";
}
.i-Cursor-Move-2:before {
  content: "\ece5";
}
.i-Cursor-Move:before {
  content: "\ece6";
}
.i-Cursor-Select:before {
  content: "\ece7";
}
.i-Cursor:before {
  content: "\ece8";
}
.i-Down:before {
  content: "\ece9";
}
.i-Download:before {
  content: "\ecea";
}
.i-Downward:before {
  content: "\eceb";
}
.i-Endways:before {
  content: "\ecec";
}
.i-Forward:before {
  content: "\eced";
}
.i-Left:before {
  content: "\ecee";
}
.i-Link:before {
  content: "\ecef";
}
.i-Next1:before {
  content: "\ecf0";
}
.i-Orientation:before {
  content: "\ecf1";
}
.i-Pointer:before {
  content: "\ecf2";
}
.i-Previous:before {
  content: "\ecf3";
}
.i-Redo:before {
  content: "\ecf4";
}
.i-Refresh:before {
  content: "\ecf5";
}
.i-Reload:before {
  content: "\ecf6";
}
.i-Remove:before {
  content: "\ecf7";
}
.i-Repeat1:before {
  content: "\ecf8";
}
.i-Reset:before {
  content: "\ecf9";
}
.i-Rewind:before {
  content: "\ecfa";
}
.i-Right:before {
  content: "\ecfb";
}
.i-Rotation-390:before {
  content: "\ecfc";
}
.i-Up:before {
  content: "\ed03";
}
.i-Upload:before {
  content: "\ed04";
}
.i-Upward:before {
  content: "\ed05";
}
.i-Yes:before {
  content: "\ed06";
}
.i-Electricity:before {
  content: "\ed0b";
}
.i-Factory:before {
  content: "\ed0d";
}
.i-Bag:before {
  content: "\ed1e";
}
.i-Bakelite:before {
  content: "\ed1f";
}
.i-Bicycle1:before {
  content: "\ed23";
}
.i-Bow-2:before {
  content: "\ed24";
}
.i-Bow-3:before {
  content: "\ed25";
}
.i-Bow:before {
  content: "\ed26";
}
.i-Hipster-Glasses-2:before {
  content: "\ed31";
}
.i-Hipster-Glasses-3:before {
  content: "\ed32";
}
.i-Hipster-Glasses:before {
  content: "\ed33";
}
.i-Hipster-Headphones:before {
  content: "\ed34";
}
.i-Hipster-Men-2:before {
  content: "\ed35";
}
.i-Hipster-Men-3:before {
  content: "\ed36";
}
.i-Hipster-Men:before {
  content: "\ed37";
}
.i-Hipster-Sunglasses-2:before {
  content: "\ed38";
}
.i-Hipster-Sunglasses-3:before {
  content: "\ed39";
}
.i-Hipster-Sunglasses:before {
  content: "\ed3a";
}
.i-Jacket:before {
  content: "\ed3b";
}
.i-Mustache-2:before {
  content: "\ed3c";
}
.i-Mustache-3:before {
  content: "\ed3d";
}
.i-Mustache:before {
  content: "\ed3e";
}
.i-Old-Camera:before {
  content: "\ed3f";
}
.i-Old-Cassette:before {
  content: "\ed40";
}
.i-Old-Sticky-2:before {
  content: "\ed41";
}
.i-Old-Sticky:before {
  content: "\ed42";
}
.i-Disk:before {
  content: "\ed56";
}
.i-Fan:before {
  content: "\ed58";
}
.i-Gamepad-2:before {
  content: "\ed59";
}
.i-Folder-Add-:before {
  content: "\edd9";
}
.i-Folder-Archive:before {
  content: "\edda";
}
.i-Folder-Binder-2:before {
  content: "\eddb";
}
.i-Folder-Binder:before {
  content: "\eddc";
}
.i-Folder-Block:before {
  content: "\eddd";
}
.i-Folder-Close:before {
  content: "\eddf";
}
.i-Folder-Download:before {
  content: "\ede2";
}
.i-Folder-Hide:before {
  content: "\ede6";
}
.i-Folder-Loading:before {
  content: "\ede8";
}
.i-Folder-Music:before {
  content: "\edeb";
}
.i-Folder-Network:before {
  content: "\edec";
}
.i-Folder-Open-2:before {
  content: "\eded";
}
.i-Folder-Open:before {
  content: "\edee";
}
.i-Folder-Organizing:before {
  content: "\edef";
}
.i-Folder-Pictures:before {
  content: "\edf0";
}
.i-Folder-Refresh:before {
  content: "\edf1";
}
.i-Folder-Remove:before {
  content: "\edf2";
}
.i-Folder-Search:before {
  content: "\edf3";
}
.i-Folder-Settings:before {
  content: "\edf4";
}
.i-Folder-Share:before {
  content: "\edf5";
}
.i-Folder-Trash:before {
  content: "\edf6";
}
.i-Folder-Upload:before {
  content: "\edf7";
}
.i-Folder-Video:before {
  content: "\edf8";
}
.i-Folder-With-Document:before {
  content: "\edf9";
}
.i-Folder-Zip:before {
  content: "\edfa";
}
.i-Folder:before {
  content: "\edfb";
}
.i-Folders:before {
  content: "\edfc";
}
.i-Add-File:before {
  content: "\ee24";
}
.i-Delete-File:before {
  content: "\ee25";
}
.i-File-Block:before {
  content: "\ee26";
}
.i-File-Bookmark:before {
  content: "\ee27";
}
.i-File-Chart:before {
  content: "\ee28";
}
.i-File-Clipboard-File--Text:before {
  content: "\ee29";
}
.i-File-Clipboard-Text--Image:before {
  content: "\ee2a";
}
.i-File-Clipboard:before {
  content: "\ee2b";
}
.i-File-Cloud:before {
  content: "\ee2c";
}
.i-File-Copy-2:before {
  content: "\ee2d";
}
.i-File-Copy:before {
  content: "\ee2e";
}
.i-File-CSV:before {
  content: "\ee2f";
}
.i-File-Download:before {
  content: "\ee30";
}
.i-File-Edit:before {
  content: "\ee31";
}
.i-File-Excel:before {
  content: "\ee32";
}
.i-File-Favorite:before {
  content: "\ee33";
}
.i-File-Fire:before {
  content: "\ee34";
}
.i-File-Graph:before {
  content: "\ee35";
}
.i-File-Hide:before {
  content: "\ee36";
}
.i-File-Horizontal-Text:before {
  content: "\ee37";
}
.i-File-Horizontal:before {
  content: "\ee38";
}
.i-File-HTML:before {
  content: "\ee39";
}
.i-File-JPG:before {
  content: "\ee3a";
}
.i-File-Link:before {
  content: "\ee3b";
}
.i-File-Loading:before {
  content: "\ee3c";
}
.i-File-Lock:before {
  content: "\ee3d";
}
.i-File-Love:before {
  content: "\ee3e";
}
.i-File-Music:before {
  content: "\ee3f";
}
.i-File-Network:before {
  content: "\ee40";
}
.i-File-Pictures:before {
  content: "\ee41";
}
.i-File-Pie:before {
  content: "\ee42";
}
.i-File-Presentation:before {
  content: "\ee43";
}
.i-File-Refresh:before {
  content: "\ee44";
}
.i-File-Search:before {
  content: "\ee45";
}
.i-File-Settings:before {
  content: "\ee46";
}
.i-File-Share:before {
  content: "\ee47";
}
.i-File-Text--Image:before {
  content: "\ee48";
}
.i-File-Trash:before {
  content: "\ee49";
}
.i-File-TXT:before {
  content: "\ee4a";
}
.i-File-Upload:before {
  content: "\ee4b";
}
.i-File-Video:before {
  content: "\ee4c";
}
.i-File-Word:before {
  content: "\ee4d";
}
.i-File-Zip:before {
  content: "\ee4e";
}
.i-File:before {
  content: "\ee4f";
}
.i-Files:before {
  content: "\ee50";
}
.i-Remove-File:before {
  content: "\ee51";
}
.i-Angry:before {
  content: "\ee53";
}
.i-Cap:before {
  content: "\ee54";
}
.i-Clown:before {
  content: "\ee55";
}
.i-Confused:before {
  content: "\ee56";
}
.i-Cool:before {
  content: "\ee57";
}
.i-Crying:before {
  content: "\ee58";
}
.i-Depression:before {
  content: "\ee59";
}
.i-Eyeglasses-Smiley-2:before {
  content: "\ee5a";
}
.i-Eyeglasses-Smiley:before {
  content: "\ee5b";
}
.i-Geek1:before {
  content: "\ee5e";
}
.i-Happy:before {
  content: "\ee5f";
}
.i-Humor:before {
  content: "\ee61";
}
.i-Laughing:before {
  content: "\ee64";
}
.i-Love1:before {
  content: "\ee65";
}
.i-Money:before {
  content: "\ee66";
}
.i-Moustache-Smiley:before {
  content: "\ee67";
}
.i-Smile:before {
  content: "\ee6d";
}
.i-Sunglasses-Smiley-2:before {
  content: "\ee6e";
}
.i-Sunglasses-Smiley:before {
  content: "\ee6f";
}
.i-Surprise:before {
  content: "\ee70";
}
.i-Thumbs-Down-Smiley:before {
  content: "\ee71";
}
.i-Thumbs-Up-Smiley:before {
  content: "\ee72";
}
.i-Tongue:before {
  content: "\ee73";
}
.i-At-Sign:before {
  content: "\ee75";
}
.i-Box-Full:before {
  content: "\ee76";
}
.i-Empty-Box:before {
  content: "\ee77";
}
.i-Envelope-2:before {
  content: "\ee78";
}
.i-Envelope:before {
  content: "\ee79";
}
.i-Inbox-Empty:before {
  content: "\ee7a";
}
.i-Inbox-Full:before {
  content: "\ee7c";
}
.i-Inbox-Into:before {
  content: "\ee7d";
}
.i-Inbox-Out:before {
  content: "\ee7e";
}
.i-Letter-Close:before {
  content: "\ee81";
}
.i-Letter-Open:before {
  content: "\ee82";
}
.i-Letter-Sent:before {
  content: "\ee83";
}
.i-Mail-2:before {
  content: "\ee84";
}
.i-Mail-3:before {
  content: "\ee85";
}
.i-Mail-Add-:before {
  content: "\ee86";
}
.i-Mail-Attachement:before {
  content: "\ee87";
}
.i-Mail-Block:before {
  content: "\ee88";
}
.i-Mail-Delete:before {
  content: "\ee89";
}
.i-Mail-Favorite:before {
  content: "\ee8a";
}
.i-Mail-Forward:before {
  content: "\ee8b";
}
.i-Mail-Gallery:before {
  content: "\ee8c";
}
.i-Mail-Inbox:before {
  content: "\ee8d";
}
.i-Mail-Link:before {
  content: "\ee8e";
}
.i-Mail-Lock:before {
  content: "\ee8f";
}
.i-Mail-Love:before {
  content: "\ee90";
}
.i-Mail-Money:before {
  content: "\ee91";
}
.i-Mail-Open:before {
  content: "\ee92";
}
.i-Mail-Outbox:before {
  content: "\ee93";
}
.i-Mail-Password:before {
  content: "\ee94";
}
.i-Mail-Photo:before {
  content: "\ee95";
}
.i-Mail-Read:before {
  content: "\ee96";
}
.i-Mail-Remove-x:before {
  content: "\ee97";
}
.i-Mail-Reply-All:before {
  content: "\ee98";
}
.i-Mail-Reply:before {
  content: "\ee99";
}
.i-Mail-Search:before {
  content: "\ee9a";
}
.i-Mail-Send:before {
  content: "\ee9b";
}
.i-Mail-Settings:before {
  content: "\ee9c";
}
.i-Mail-Unread:before {
  content: "\ee9d";
}
.i-Mail-Video:before {
  content: "\ee9e";
}
.i-Mail-with-At-Sign:before {
  content: "\ee9f";
}
.i-Mail-With-Cursors:before {
  content: "\eea0";
}
.i-Mail:before {
  content: "\eea1";
}
.i-Mailbox-Empty:before {
  content: "\eea2";
}
.i-Mailbox-Full:before {
  content: "\eea3";
}
.i-New-Mail:before {
  content: "\eea4";
}
.i-Post-Mail-2:before {
  content: "\eea5";
}
.i-Post-Mail:before {
  content: "\eea6";
}
.i-Spam-Mail:before {
  content: "\eea7";
}
.i-Stamp-2:before {
  content: "\eea8";
}
.i-Stamp:before {
  content: "\eea9";
}
.i-Voicemail:before {
  content: "\eeaa";
}
.i-Alpha:before {
  content: "\eeab";
}
.i-Beta:before {
  content: "\eeac";
}
.i-Blackboard:before {
  content: "\eead";
}
.i-Book:before {
  content: "\eeae";
}
.i-Bookmark:before {
  content: "\eeaf";
}
.i-Diploma-2:before {
  content: "\eeb3";
}
.i-Diploma:before {
  content: "\eeb4";
}
.i-Eraser-2:before {
  content: "\eeb5";
}
.i-Notepad-2:before {
  content: "\eeba";
}
.i-Notepad:before {
  content: "\eebb";
}
.i-Open-Book:before {
  content: "\eebc";
}
.i-Pen-2:before {
  content: "\eebd";
}
.i-Pen-3:before {
  content: "\eebe";
}
.i-Pen-4:before {
  content: "\eebf";
}
.i-Pen-5:before {
  content: "\eec0";
}
.i-Pen-6:before {
  content: "\eec1";
}
.i-Pi:before {
  content: "\eec2";
}
.i-Pipette:before {
  content: "\eec3";
}
.i-Quill-2:before {
  content: "\eec4";
}
.i-Quill-3:before {
  content: "\eec5";
}
.i-Quill:before {
  content: "\eec6";
}
.i-Spell-Check-ABC:before {
  content: "\eec7";
}
.i-Spell-Check:before {
  content: "\eec8";
}
.i-Stapler:before {
  content: "\eec9";
}
.i-Student-Hat-2:before {
  content: "\eeca";
}
.i-Student-Hat:before {
  content: "\eecb";
}
.i-Sum1:before {
  content: "\eecc";
}
.i-University:before {
  content: "\eecd";
}
.i-Computer-2:before {
  content: "\eece";
}
.i-Monitor-2:before {
  content: "\eed9";
}
.i-Monitor-3:before {
  content: "\eeda";
}
.i-Monitor-5:before {
  content: "\eedc";
}
.i-Monitor-Vertical:before {
  content: "\eedd";
}
.i-Orientation-2:before {
  content: "\eedf";
}
.i-Board:before {
  content: "\eef5";
}
.i-Brush:before {
  content: "\eef6";
}
.i-Clamp:before {
  content: "\eef7";
}
.i-CMYK:before {
  content: "\eef8";
}
.i-Idea:before {
  content: "\eefe";
}
.i-Knife1:before {
  content: "\eeff";
}
.i-Marker:before {
  content: "\ef00";
}
.i-Note:before {
  content: "\ef01";
}
.i-Paintbrush:before {
  content: "\ef02";
}
.i-Vector:before {
  content: "\ef18";
}
.i-Big-Data:before {
  content: "\ef19";
}
.i-Data-Backup:before {
  content: "\ef1a";
}
.i-Data-Block:before {
  content: "\ef1b";
}
.i-Data-Center:before {
  content: "\ef1c";
}
.i-Data-Clock:before {
  content: "\ef1d";
}
.i-Data-Cloud:before {
  content: "\ef1e";
}
.i-Data-Compress:before {
  content: "\ef1f";
}
.i-Data-Copy:before {
  content: "\ef20";
}
.i-Data-Download:before {
  content: "\ef21";
}
.i-Data-Financial:before {
  content: "\ef22";
}
.i-Data-Key:before {
  content: "\ef23";
}
.i-Data-Lock:before {
  content: "\ef24";
}
.i-Data-Network:before {
  content: "\ef25";
}
.i-Data-Password:before {
  content: "\ef26";
}
.i-Data-Power:before {
  content: "\ef27";
}
.i-Data-Refresh:before {
  content: "\ef28";
}
.i-Data-Save:before {
  content: "\ef29";
}
.i-Data-Search:before {
  content: "\ef2a";
}
.i-Data-Security:before {
  content: "\ef2b";
}
.i-Data-Settings:before {
  content: "\ef2c";
}
.i-Data-Sharing:before {
  content: "\ef2d";
}
.i-Data-Shield:before {
  content: "\ef2e";
}
.i-Data-Signal:before {
  content: "\ef2f";
}
.i-Data-Storage:before {
  content: "\ef30";
}
.i-Data-Stream:before {
  content: "\ef31";
}
.i-Data-Transfer:before {
  content: "\ef32";
}
.i-Data-Unlock:before {
  content: "\ef33";
}
.i-Data-Upload:before {
  content: "\ef34";
}
.i-Data-Yes:before {
  content: "\ef35";
}
.i-Data:before {
  content: "\ef36";
}
.i-Hub:before {
  content: "\ef37";
}
.i-Address-Book-2:before {
  content: "\ef38";
}
.i-Address-Book:before {
  content: "\ef39";
}
.i-Bell1:before {
  content: "\ef3a";
}
.i-Bird-Delivering-Letter:before {
  content: "\ef3b";
}
.i-Communication-Tower-2:before {
  content: "\ef3c";
}
.i-Communication-Tower:before {
  content: "\ef3d";
}
.i-Fax:before {
  content: "\ef3e";
}
.i-Megaphone:before {
  content: "\ef3f";
}
.i-Newspaper-2:before {
  content: "\ef40";
}
.i-Newspaper:before {
  content: "\ef41";
}
.i-Old-Telephone:before {
  content: "\ef42";
}
.i-Plastic-Cup-Phone-2:before {
  content: "\ef43";
}
.i-Plastic-Cup-Phone:before {
  content: "\ef44";
}
.i-Router-2:before {
  content: "\ef45";
}
.i-Router:before {
  content: "\ef46";
}
.i-Telephone-2:before {
  content: "\ef47";
}
.i-Telephone:before {
  content: "\ef48";
}
.i-VPN:before {
  content: "\ef49";
}
.i-Walkie-Talkie:before {
  content: "\ef4a";
}
.i-Wifi-2:before {
  content: "\ef4b";
}
.i-Wifi:before {
  content: "\ef4c";
}
.i-Wireless:before {
  content: "\ef4d";
}
.i-Block-Cloud:before {
  content: "\ef4e";
}
.i-Cloud:before {
  content: "\ef4f";
}
.i-Cloud-:before {
  content: "\ef50";
}
.i-Cloud-Camera:before {
  content: "\ef51";
}
.i-Cloud-Computer:before {
  content: "\ef52";
}
.i-Cloud-Email:before {
  content: "\ef53";
}
.i-Cloud-Laptop:before {
  content: "\ef54";
}
.i-Cloud-Lock:before {
  content: "\ef55";
}
.i-Cloud-Music:before {
  content: "\ef56";
}
.i-Cloud-Picture:before {
  content: "\ef57";
}
.i-Cloud-Remove:before {
  content: "\ef58";
}
.i-Cloud-Secure:before {
  content: "\ef59";
}
.i-Cloud-Settings:before {
  content: "\ef5a";
}
.i-Cloud-Smartphone:before {
  content: "\ef5b";
}
.i-Cloud-Tablet:before {
  content: "\ef5c";
}
.i-Cloud-Video:before {
  content: "\ef5d";
}
.i-Cloud1:before {
  content: "\ef5e";
}
.i-Clouds:before {
  content: "\ef5f";
}
.i-Device-Sync-with-Cloud:before {
  content: "\ef60";
}
.i-Download-from-Cloud:before {
  content: "\ef61";
}
.i-People-on-Cloud:before {
  content: "\ef62";
}
.i-Search-on-Cloud:before {
  content: "\ef63";
}
.i-Share-on-Cloud:before {
  content: "\ef64";
}
.i-Belt-3:before {
  content: "\ef6b";
}
.i-Wallet-2:before {
  content: "\ef9f";
}
.i-Womans-Underwear-2:before {
  content: "\efa0";
}
.i-Womans-Underwear:before {
  content: "\efa1";
}
.i-Dec:before {
  content: "\efa3";
}
.i-Angel:before {
  content: "\efa4";
}
.i-Bow1:before {
  content: "\efa5";
}
.i-Christmas-Ball:before {
  content: "\efa7";
}
.i-Christmas-Bell:before {
  content: "\efa8";
}
.i-Christmas-Candle:before {
  content: "\efa9";
}
.i-Christmas-Hat:before {
  content: "\efaa";
}
.i-Present:before {
  content: "\efb6";
}
.i-Tactic:before {
  content: "\efca";
}
.i-AMX:before {
  content: "\efcc";
}
.i-ATM:before {
  content: "\efcd";
}
.i-Bar-Chart-2:before {
  content: "\efce";
}
.i-Bar-Chart-3:before {
  content: "\efcf";
}
.i-Bar-Chart-4:before {
  content: "\efd0";
}
.i-Bar-Chart-5:before {
  content: "\efd1";
}
.i-Bar-Chart:before {
  content: "\efd2";
}
.i-Calculator-2:before {
  content: "\efd3";
}
.i-Calculator-3:before {
  content: "\efd4";
}
.i-Calculator:before {
  content: "\efd5";
}
.i-Calendar-2:before {
  content: "\efd6";
}
.i-Calendar-3:before {
  content: "\efd7";
}
.i-Calendar-4:before {
  content: "\efd8";
}
.i-Calendar:before {
  content: "\efd9";
}
.i-Claps:before {
  content: "\efda";
}
.i-Coin:before {
  content: "\efdb";
}
.i-Coins-2:before {
  content: "\efdc";
}
.i-Coins-3:before {
  content: "\efdd";
}
.i-Coins:before {
  content: "\efde";
}
.i-Credit-Card-2:before {
  content: "\efdf";
}
.i-Credit-Card-3:before {
  content: "\efe0";
}
.i-Credit-Card:before {
  content: "\efe1";
}
.i-Diamond:before {
  content: "\efe2";
}
.i-Dollar-Sign-2:before {
  content: "\efe3";
}
.i-Dollar-Sign:before {
  content: "\efe4";
}
.i-Dollar:before {
  content: "\efe5";
}
.i-Euro-Sign-2:before {
  content: "\efe6";
}
.i-Euro-Sign:before {
  content: "\efe7";
}
.i-Euro:before {
  content: "\efe8";
}
.i-Financial:before {
  content: "\efe9";
}
.i-Handshake:before {
  content: "\efea";
}
.i-Line-Chart-2:before {
  content: "\efeb";
}
.i-Line-Chart-3:before {
  content: "\efec";
}
.i-Line-Chart-4:before {
  content: "\efed";
}
.i-Line-Chart:before {
  content: "\efee";
}
.i-Money-2:before {
  content: "\eff0";
}
.i-Money-Bag:before {
  content: "\eff1";
}
.i-Money1:before {
  content: "\eff2";
}
.i-Pie-Chart-2:before {
  content: "\eff3";
}
.i-Pie-Chart-3:before {
  content: "\eff4";
}
.i-Pie-Chart:before {
  content: "\eff5";
}
.i-Pound-Sign-2:before {
  content: "\eff6";
}
.i-Pound-Sign:before {
  content: "\eff7";
}
.i-Pound:before {
  content: "\eff8";
}
.i-Safe-Box1:before {
  content: "\eff9";
}
.i-Token-:before {
  content: "\effa";
}
.i-Visa:before {
  content: "\effb";
}
.i-Wallet-21:before {
  content: "\effc";
}
.i-Wallet:before {
  content: "\effd";
}
.i-Bank:before {
  content: "\effe";
}
.i-Building:before {
  content: "\f003";
}
.i-Clothing-Store:before {
  content: "\f00a";
}
.i-Column-2:before {
  content: "\f00c";
}
.i-Column-3:before {
  content: "\f00d";
}
.i-Column:before {
  content: "\f00e";
}
.i-Door:before {
  content: "\f00f";
}
.i-Factory1:before {
  content: "\f014";
}
.i-Home-2:before {
  content: "\f016";
}
.i-Home-4:before {
  content: "\f018";
}
.i-Home1:before {
  content: "\f019";
}
.i-Hospital1:before {
  content: "\f01a";
}
.i-Hotel:before {
  content: "\f01b";
}
.i-Japanese-Gate:before {
  content: "\f01c";
}
.i-Police-Station:before {
  content: "\f027";
}
.i-Post-Office:before {
  content: "\f028";
}
.i-Structure:before {
  content: "\f02e";
}
.i-Tower-2:before {
  content: "\f032";
}
.i-Tower:before {
  content: "\f034";
}
.i-University1:before {
  content: "\f035";
}
.i-Window:before {
  content: "\f037";
}
.i-Android:before {
  content: "\f039";
}
.i-Chrome:before {
  content: "\f03a";
}
.i-Debian:before {
  content: "\f03b";
}
.i-Firefox:before {
  content: "\f03c";
}
.i-Internet-Explorer:before {
  content: "\f03d";
}
.i-iOS-Apple:before {
  content: "\f03e";
}
.i-Linux:before {
  content: "\f03f";
}
.i-Netscape:before {
  content: "\f040";
}
.i-Opera:before {
  content: "\f041";
}
.i-Windows-Microsoft:before {
  content: "\f044";
}
.i-Brain1:before {
  content: "\f047";
}
.i-Cardiovascular:before {
  content: "\f048";
}
.i-Elbow:before {
  content: "\f04a";
}
.i-Eye-Visible:before {
  content: "\f04d";
}
.i-Eye1:before {
  content: "\f04e";
}
.i-Eyebrow-2:before {
  content: "\f04f";
}
.i-Eyebrow:before {
  content: "\f051";
}
.i-Face-Style-2:before {
  content: "\f052";
}
.i-Face-Style-3:before {
  content: "\f053";
}
.i-Face-Style-4:before {
  content: "\f054";
}
.i-Face-Style-5:before {
  content: "\f055";
}
.i-Face-Style-6:before {
  content: "\f056";
}
.i-Finger:before {
  content: "\f058";
}
.i-Fingerprint-2:before {
  content: "\f059";
}
.i-Fingerprint:before {
  content: "\f05a";
}
.i-Foot:before {
  content: "\f05c";
}
.i-Hand:before {
  content: "\f05e";
}
.i-Heart1:before {
  content: "\f060";
}
.i-Leg:before {
  content: "\f063";
}
.i-Mustache-31:before {
  content: "\f065";
}
.i-Mustache-4:before {
  content: "\f066";
}
.i-Arrow-Back-2:before {
  content: "\f06c";
}
.i-Arrow-Back-3:before {
  content: "\f06d";
}
.i-Arrow-Back:before {
  content: "\f06e";
}
.i-Arrow-Down-2:before {
  content: "\f06f";
}
.i-Arrow-Down-3:before {
  content: "\f070";
}
.i-Arrow-Down-in-Circle:before {
  content: "\f071";
}
.i-Arrow-Down:before {
  content: "\f072";
}
.i-Arrow-Forward-2:before {
  content: "\f073";
}
.i-Arrow-Forward:before {
  content: "\f074";
}
.i-Arrow-Left-2:before {
  content: "\f075";
}
.i-Arrow-Left-in-Circle:before {
  content: "\f076";
}
.i-Arrow-Left:before {
  content: "\f077";
}
.i-Arrow-Next:before {
  content: "\f078";
}
.i-Arrow-Refresh-2:before {
  content: "\f079";
}
.i-Arrow-Refresh:before {
  content: "\f07a";
}
.i-Arrow-Right-2:before {
  content: "\f07b";
}
.i-Arrow-Right-in-Circle:before {
  content: "\f07c";
}
.i-Arrow-Right:before {
  content: "\f07d";
}
.i-Arrow-Turn-Left:before {
  content: "\f07e";
}
.i-Arrow-Turn-Right:before {
  content: "\f07f";
}
.i-Arrow-Up-2:before {
  content: "\f080";
}
.i-Arrow-Up-3:before {
  content: "\f081";
}
.i-Arrow-Up-in-Circle:before {
  content: "\f082";
}
.i-Arrow-Up:before {
  content: "\f083";
}
.i-Arrow-X-Left:before {
  content: "\f084";
}
.i-Arrow-X-Right:before {
  content: "\f085";
}
.i-Bottom-To-Top:before {
  content: "\f086";
}
.i-Down-2:before {
  content: "\f087";
}
.i-Down-3:before {
  content: "\f088";
}
.i-Down1:before {
  content: "\f089";
}
.i-Download1:before {
  content: "\f08a";
}
.i-End1:before {
  content: "\f08b";
}
.i-Fit-To-2:before {
  content: "\f08c";
}
.i-Fit-To:before {
  content: "\f08d";
}
.i-Full-Screen-2:before {
  content: "\f08e";
}
.i-Full-Screen:before {
  content: "\f08f";
}
.i-Go-Bottom:before {
  content: "\f090";
}
.i-Go-Top:before {
  content: "\f091";
}
.i-Left---Right-3:before {
  content: "\f092";
}
.i-Left---Right:before {
  content: "\f093";
}
.i-Left-2:before {
  content: "\f094";
}
.i-Left-3:before {
  content: "\f095";
}
.i-Left-To-Right:before {
  content: "\f096";
}
.i-Left1:before {
  content: "\f097";
}
.i-Loop:before {
  content: "\f098";
}
.i-Navigat-Start:before {
  content: "\f099";
}
.i-Navigate-End:before {
  content: "\f09a";
}
.i-Reload-2:before {
  content: "\f09b";
}
.i-Reload1:before {
  content: "\f09c";
}
.i-Repeat-21:before {
  content: "\f09d";
}
.i-Repeat-3:before {
  content: "\f09e";
}
.i-Repeat-4:before {
  content: "\f09f";
}
.i-Repeat2:before {
  content: "\f0a0";
}
.i-Right-2:before {
  content: "\f0a1";
}
.i-Right-3:before {
  content: "\f0a2";
}
.i-Right-To-Left:before {
  content: "\f0a3";
}
.i-Right1:before {
  content: "\f0a4";
}
.i-Shuffle-21:before {
  content: "\f0a5";
}
.i-Shuffle1:before {
  content: "\f0a6";
}
.i-Start1:before {
  content: "\f0a7";
}
.i-Sync:before {
  content: "\f0a8";
}
.i-To-Bottom-2:before {
  content: "\f0a9";
}
.i-To-Bottom:before {
  content: "\f0aa";
}
.i-To-Left:before {
  content: "\f0ab";
}
.i-To-Right:before {
  content: "\f0ac";
}
.i-To-Top-2:before {
  content: "\f0ad";
}
.i-To-Top:before {
  content: "\f0ae";
}
.i-Top-To-Bottom:before {
  content: "\f0af";
}
.i-Triangle-Arrow-Down:before {
  content: "\f0b0";
}
.i-Triangle-Arrow-Left:before {
  content: "\f0b1";
}
.i-Triangle-Arrow-Right:before {
  content: "\f0b2";
}
.i-Triangle-Arrow-Up:before {
  content: "\f0b3";
}
.i-Turn-Down-2:before {
  content: "\f0b4";
}
.i-Turn-Down-From-Left:before {
  content: "\f0b5";
}
.i-Turn-Down-From-Right:before {
  content: "\f0b6";
}
.i-Turn-Down:before {
  content: "\f0b7";
}
.i-Turn-Left-3:before {
  content: "\f0b8";
}
.i-Turn-Left:before {
  content: "\f0b9";
}
.i-Turn-Right-3:before {
  content: "\f0ba";
}
.i-Turn-Right:before {
  content: "\f0bb";
}
.i-Turn-Up-2:before {
  content: "\f0bc";
}
.i-Turn-Up:before {
  content: "\f0bd";
}
.i-Up---Down-3:before {
  content: "\f0be";
}
.i-Up---Down:before {
  content: "\f0bf";
}
.i-Up-2:before {
  content: "\f0c0";
}
.i-Up-3:before {
  content: "\f0c1";
}
.i-Up1:before {
  content: "\f0c2";
}
.i-Upload1:before {
  content: "\f0c3";
}
.i-Arrow-Around:before {
  content: "\f0c4";
}
.i-Arrow-Barrier:before {
  content: "\f0c5";
}
.i-Arrow-Circle:before {
  content: "\f0c6";
}
.i-Arrow-Cross:before {
  content: "\f0c7";
}
.i-Arrow-Fork:before {
  content: "\f0c8";
}
.i-Arrow-From:before {
  content: "\f0c9";
}
.i-Arrow-Inside-45:before {
  content: "\f0ca";
}
.i-Arrow-Inside-Gap-45:before {
  content: "\f0cb";
}
.i-Arrow-Inside-Gap:before {
  content: "\f0cc";
}
.i-Arrow-Inside:before {
  content: "\f0cd";
}
.i-Arrow-Loop:before {
  content: "\f0d1";
}
.i-Arrow-Mix:before {
  content: "\f0d3";
}
.i-Arrow-Out-Left:before {
  content: "\f0d4";
}
.i-Arrow-Out-Right:before {
  content: "\f0d5";
}
.i-Arrow-Outside-45:before {
  content: "\f0d6";
}
.i-Arrow-Outside-Gap-45:before {
  content: "\f0d7";
}
.i-Arrow-Outside-Gap:before {
  content: "\f0d8";
}
.i-Arrow-Outside:before {
  content: "\f0d9";
}
.i-Arrow-Over:before {
  content: "\f0da";
}
.i-Arrow-Shuffle:before {
  content: "\f0db";
}
.i-Resize:before {
  content: "\f0e4";
}
.i-Chicken:before {
  content: "\f0f2";
}
.i-Align-Justify-All:before {
  content: "\f11a";
}
.i-Align-Justify-Center:before {
  content: "\f11b";
}
.i-Align-Justify-Left:before {
  content: "\f11c";
}
.i-Align-Justify-Right:before {
  content: "\f11d";
}
.i-Align-Left:before {
  content: "\f11e";
}
.i-Align-Right:before {
  content: "\f11f";
}
