.customizer {
    position: fixed;
    z-index: 9999;
    top: 45%;
    right: -112px;
    transition: .3s all ease-in-out;
    &.open {
        right: 0;
    }
    .handle {
        position: absolute;
        display: flex;
        top: 8px;
        left: -24px;
        background: $background;
        cursor: pointer;
        padding: 10px 2px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        box-shadow: -3px 0px 4px rgba(0, 0, 0, 0.06);
        color: $primary;
        font-size: 20px;
        transition: .3s all ease-in-out;
        &:hover {
            background: $primary;
            color: $white;
        }
    }
    .customizer-body {
        padding: 1rem;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        background: $background;
        box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.06), 0 1px 4px rgba(0, 0, 0, 0.08);

        .colors {
            .color {
                width: 24px;
                height: 24px;
                display: inline-block;
                border-radius: 50%;
                margin: 8px;
                text-indent: -10000px;
                &.purple {
                    background: #663399;
                }
                &.blue {
                    background: #2f47c2;
                }
            }
        }
    }
}