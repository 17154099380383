.auth-layout-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-size: cover;
    .auth-content {
        max-width: 500px;
        margin: auto;
    }
}
.auth-logo {
    img {
        width: 100px;
        height: 100px;
    }
}

@media (min-width: 370px) {
    .auth-layout-wrap {
        .auth-content {
            min-width: 360px;
        }
    }
}

@media (max-width: 500px) {
    .auth-layout-wrap {
        .auth-content {
            width: 100%;
            padding: 15px;
        }
    }
}