/*
 *
 *   GULL - Angular Bootstrap Admin Template
 *
 *
*/

@import '~perfect-scrollbar/css/perfect-scrollbar.css';
@import '~@swimlane/ngx-datatable/release/index.css';
@import '~@swimlane/ngx-datatable/release/themes/material.css';
@import '~@swimlane/ngx-datatable/release/assets/icons.css';
@import '~ngx-toastr/toastr.css';
@import "~angular-calendar/css/angular-calendar.css";
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';
@import '~highlight.js/styles/github.css';
@import '~ladda/dist/ladda.min.css';
@import "../iconsmind/iconsmind.css";
@import "../fontawesome/css/all.css";
@import "../../icons/flaticon-house/flaticon";
@import 'variables';
@import "~@ng-select/ng-select/themes/default.theme.css";

@import 'themes/lite-blue';
// @import 'themes/lite-purple';
@import "~bootstrap/scss/bootstrap";
@import 'globals/globals';
@import 'fonts';
@import 'custom';