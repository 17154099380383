.chat-sidebar-container {
    height: calc(100vh - 80px);
    min-height: unset;
    .chat-topbar {
        height: 100px;
        z-index: 3;
    }
    .chat-content-wrap {
        .chat-content {
            padding: 1.5rem 1.5rem 0;
            position: relative;
            height: calc(100vh - 300px);
            .message {
                position: relative;
                background: $gray-100;
                border-radius: 10px;
                padding: 1rem;
                &:before {
                    position: absolute;
                    content: "";
                    top: 14px;
                    right: -5px;
                    background: transparent;
                    border-style: solid;
                    border-width: 0 10px 10px 0px;
                    border-color: transparent transparent $gray-100 transparent;
                    transform: rotate(-135deg);
                }

                .user-message {
                    &:before {
                        border-color: transparent transparent $blue transparent !important;
                    }
                }
            }
            .user {
                .message {
                    &:before {
                        left: -5px;
                        right: auto;
                        transform: rotate(45deg);
                    }
                }
            }
            .chat-input-area {
                position: absolute;
                width: 100%;
                bottom: 0;
                left: 0;
                right: 0;
            }
        }
        .spinner-glow {
            position: absolute;
            left: 0;
            right: 0;
            top: calc(50% - 10px);
        
        }
    }
    .chat-sidebar-wrap {
        width: 350px;
        .contacts-scrollable {
            position: relative;
            height: calc(100vh - 215px);
            .contact {
                position: relative;
                cursor: pointer;
                transition: .15s all ease-in;

                &:hover {
                    background: $gray-100;
                }
                &.unread {
                    background-color: #ccd1e9;
                    &:hover {
                        background-color: #afb5d4 !important;
                    }
                }
                &.selected {
                    background-color: #c6c9d1;
                    &:hover {
                        background-color: #c0c3cc !important;
                    }
                }
                &.online {
                    &:before {
                        background: $success;
                    }
                }
            }
        }
    }
}

.list-avatar-container {
    position: relative;
    border-radius: 50%;
    width: 40px;
    min-width: 40px;
    height: 40px;
    margin-right: .75rem;

    img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }
}

.main-avatar-container {
    position: relative;
    width: 60px;
    height: 60px;
    margin-right: .75rem;

    img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
    }
}

.avatar-shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    box-shadow: inset 0px 0px 10px 2px rgba(0,0,0,0.18);
}

.list-avatar-shadow {
    width: 40px;
    height: 40px;
}
